.hamburgerMenuContainer {
    position: relative;
    display: inline-block;
  }
  
  .hamburgerIcon {
    position:relative;
    top: -1.1rem;
    width: 2rem;
    height: 1.6rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  
  .line {
    width: 1.3rem;
    height: .2rem;
    background-color: var(--primary-color);
    transition: background-color 0.3s ease;
  }
  
  .hamburgerIcon:hover .line {
    background-color: var(--secondary-color);
  }
  
  .dropdownMenu {
    position: absolute;
    top: 4.5rem; /* Aligns the top of the menu with the hamburger icon */
    left: 6.2rem; /* Positions the menu to the right of the hamburger icon */
    background-color: var(--primary-color);
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .menuItem {
    display: block;
    padding: 10px 15px;
    text-decoration: none;
    color: var(--tertiary-color);
    transition: background-color 0.2s ease;
    white-space: nowrap;
  }
  
  .menuItem:hover {
    background-color: var(--primary-alternate-color);
  }
  