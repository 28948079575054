.hamburgerMenu {
    position: relative;
    display: inline-block;
    padding:0;
    margin:0;
    top: .5rem;
  }
  
  .hamburgerIcon {
    font-size: 30px;
    cursor: pointer;
    background: none;
    border: none;
    color:var(--primary-color);
    padding:0;
    margin:0;
    /*make the content left justified and top*/
    display: flex;
    justify-content: left;
    align-items: top;
    text-align: left;

  }
  
  .dropdownMenu {
    position: relative;
    background-color: var(--primary-color);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    border-radius: .6rem;
    padding: .5rem;
    box-sizing: border-box;
    width: 100%;
    right: 5.5rem;

  }

  @media (max-width: 768px) {
    .dropdownMenu {
      left: -2rem;
      top: 3.5rem;
    }
  }
  
  .menuItem {
    display: block;
    padding: 12px 16px;
    text-decoration: none;
    color: var(--tertiary-color);
    font-size: 1rem;
    border-radius: .6rem;
    box-sizing: border-box;
    width: 100%;
    /*no wrap text*/
    white-space: nowrap;
 

  }
  
  .menuItem:hover {
    background-color: var(--background-color);
  }