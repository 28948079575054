.main{
    position:relative;
    left: 6rem;
    top: 2rem;
    max-width: 100%;
    overflow: hidden;
    margin-bottom: 2rem;
}

.logoWords{
    color: var(--secondary-color);
    font-size:2rem;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;    
}

/*created using a PORTAL so it's attached to the document.body directly because it outside of a no-overflow parent*/
.pageTitle{
    position: absolute;
    top: 2rem;
    right: 10vw;

    color: var(--secondary-color);
    font-size: 4rem;
    font-family: 'Roboto', sans-serif;
    font-weight: regular;
    z-index: 1000;
    opacity: .15;
}

/*if mobile size then*/
@media (max-width: 768px){
    .pageTitle{
        right: 10vw;
        font-size:2.5rem;
    }
}


.moto{
    position:relative;
    top: -1.8rem;
    left: 7rem;
}

.moto img{
    position:relative;
    top: -1rem;
}