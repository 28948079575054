.main{
    color:white;
    font-size: 3rem;
}



  .appHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    margin-bottom: 6rem;
    color: var(--secondary-color);
    font-size: 3rem;
    
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .headerInner{
    position: relative;
  }

  .motoWrapper{
    position:absolute;
    left: 26rem;
    top: 10rem;
    display: flex; /* Use Flexbox */
    white-space: nowrap; /* Prevent content from wrapping */  
    /*align left*/
    display: flex;
    justify-content: left;
    align-items: left;
    text-align: left;
  }
  
@media (max-width: 768px) {
    .motoWrapper {
        left: 20rem;
    }
}

.cardContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  font-size: 1rem;
}


.hamburger{
  position:absolute;
  top: 5rem;
  left:42rem;
  padding: 0;
  justify-content: left;
  align-items: top;
  text-align: left;
  width: 7.5rem;  
}



@media (max-width: 768px) {
  .hamburger{
    position:absolute;
    top: 10rem;
    left: 0rem;
    box-sizing: border-box;
    width: auto;
    display: flex;
  }
}