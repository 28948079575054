@font-face {
    font-family: 'Poppins';
    src: url('./Poppins/Poppins-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: regular;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./Roboto/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: regular;
  font-style: italic;
}

  

