:root{
    --primary-color: #fff4ea;
    --primary-alternate-color: #efcecb;
    --secondary-color: #ffd964;
    --secondary-alternate-color: #ffc61a;
    --tertiary-color: #946c0a;
    --background-color: rgb(55, 42, 40);
    --extreme-highlight: red;
    --lesser-highlight: #a13170;


    --overlay-transparency: 0.7;
}

