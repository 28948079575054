.cardViewContainer {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  color: var(--color-text);
  max-width: 100%;
  overflow: hidden;
}


.profileSelector {
  /*position on the right hand side of the screen right aligned witout using absolute positioning*/
  display: flex;
  justify-content: flex-end;
  padding-right: 2rem;

  
  position: relative;
  top: 0rem;
  opacity: .6;
}

/* Toolbar Styles */
.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  opacity: .4;
}

.leftToolbar,
.rightToolbar {
  display: flex;
  align-items: center;
}

.itemsPerPageLabel {
  margin-right: 1rem;
  display: flex;
  align-items: center;
  color:var(--primary-color);
  font-family: 'Roboto', sans-serif;
  font-weight: regular;

}

.itemsPerPageSelect {
  margin-left: 0.5rem;
  padding: 0.5rem;
  border: 1px solid var(--secondary-color);
  border-radius: 0.25rem;
  background-color: var(--primary-alternate-color);
  color: var(--tertiary-color);
  font-family: 'Roboto', sans-serif;
  font-weight: regular;

}

.filterInput {
  margin-bottom: 0.5rem;
  padding: 0.75rem;
  border: 3px solid var(--secondary-color);
  border-radius: 0.5rem;
  width: 100%;
  font-size: 1rem;
  background-color: var(--primary-alternate-color);
  max-width: 30rem;
  display: block;
  font-family: 'Roboto', sans-serif;
  font-weight: regular;

}

.sortButton {
  padding: 0.75rem 1.5rem;
  margin-bottom: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  background-color: var(--secondary-alternate-color);
  color: white;
  cursor: pointer;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  font-weight: regular;

}

.sortButton:hover {
  background-color: var(--secondary-color);
}

.cardGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 1.5rem;
  width: 100%;
}

.filterIcon {
  display: inline-block;
  cursor: pointer;
  margin-bottom: 0rem;
  height: 2rem;
  width: 2rem;
  background-image: url('../../../public/images/filterPrimary.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  outline: none;
  background-color: transparent;
}

.filterIconSelected{
  display: inline-block;
  cursor: pointer;
  margin-bottom: 0rem;
  height: 2rem;
  width: 2rem;  
  background-image: url('../../../public/images/filterLesserHighlight.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  outline: none;
  background-color: transparent;  
}

.addIcon {
  display: inline-block;
  cursor: pointer;
  margin-bottom: 0rem;
  height: 2rem;
  width: 2rem;
  background-image: url('../../../public/images/addIconPrimary.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  outline: none;
  background-color: transparent;
}

.filterIcon:hover {
  background-image: url('../../../public/images/filterPrimaryAlt.png');
}

.addIcon:hover {
  background-image: url('../../../public/images/addIconPrimaryAlt.png');
}

.hidden {
  display: none;
}

/* Filter Section Transition */
.filterSection {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  margin-bottom: 0.5rem;
  font-family: 'Roboto', sans-serif;
  font-weight: regular;

}

.filterSection.show {
  max-height: 200px;
}

/* Pagination Styles */
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  color:var(--tertiary-color);
  opacity: .4;
  font-family: 'Roboto', sans-serif;
  font-weight: regular;

}

.paginationButton {
  margin: 0 0.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid var(--tertiary-color);
  background-color: var(--secondary-alternate-color);
  color: var(--primary-color);
  border-radius: 0.25rem;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-weight: regular;

}
.paginationButton:hover {
  background-color: var(--secondary-color);
}

.paginationButton:disabled {
  background-color:var(--tertiary-color);
  border: 1px solid var(--secondary-color);
  cursor: not-allowed;
}

.paginationInfo {
  margin: 0 0.5rem;
  color:var(--primary-color);
  font-family: 'Roboto', sans-serif;
  font-weight: regular;

}

.paginationLabel {
  display: flex;
  align-items: center;
  margin: 0 0.5rem;
  color:var(--primary-color);
  font-family: 'Roboto', sans-serif;
  font-weight: regular;

}

.paginationInput {
  width: 3rem;
  margin-left: 0.5rem;
  padding: 0.25rem;
  text-align: center;
  border: 1px solid var(--secondary-color);
  border-radius: 0.25rem;
  background-color: var(--primary-alternate-color);
  color: var(--tertiary-color);
  font-family: 'Roboto', sans-serif;
  font-weight: regular;

}

.totalItems {
  margin-left: 0.5rem;
  color:var(--primary-color);
  font-family: 'Roboto', sans-serif;
  font-weight: regular;

}

