.modalOverlay {
    position: fixed; /* Change this line */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, var(--overlay-transparency));
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}
  
.modalContent {
    background: var(--primary-color);
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border: 1rem solid var(--primary-alternate-color);
}

.modalContent h2 {
    color: var(--lesser-highlight);
    margin-top: 0;
}

.modalContent p {
    color: var(--tertiary-color);
}
  


.modalContent button {
    margin: 0 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid var(--tertiary-color);
    background-color: var(--secondary-alternate-color);
    color: var(--primary-color);
    border-radius: 0.25rem;
    cursor: pointer;
}
.modalContent button:hover {
    background-color: var(--secondary-color);
}