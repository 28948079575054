

  
  .deckCard {

    min-width: 21rem;
    min-height: 10.5rem;
    max-height: 10.5rem;
    box-sizing: border-box;
    padding-top: 1rem;

    background-color: var(--primary-color);
    padding: .5rem;
    
    border-radius: .7rem;
    transition: transform 0.3s ease, font-weight 0.3s ease, background-image 0.3s ease;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    font-family: Poppins, sans-serif;
    font-weight: bold;
    font-size: 1.1rem;
    display: flex; /* Use Flexbox */
    flex-direction: column; /* Arrange children in a column */
    justify-content: center; /* Center children vertically */
    align-items: center; /* Center children horizontally */
    text-align: center; /* Center text horizontally */
    font-family: 'Roboto', sans-serif;
    font-weight: regular;
      
  }
  
  .deckCard::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: .3;
    transition: opacity .3s ease;
    transition: background-image .9s ease;
    z-index: 0;
    background-image: var(--backgroundUrl);
  }
  
  .deckCard:hover {
    transform: translateY(-10px);
  }

  .deckCard:hover::before {
    opacity: 0.3;
    transition: background-image .9s ease;
    background-image: var(--backgroundUrlHover);
  }
  
  .deckCard h2, .featureCard p {
    position: relative;
    z-index: 2;
    color: #333;
  }
  

  .content{
    font-weight: regular;
    font-family: Roboto, sans-serif;
    font-size: .8rem;
    display: flex; /* Use Flexbox */
    flex-direction: column; /* Arrange children in a column */
    justify-content: left; /* Center children vertically */
    align-items: left; /* Center children horizontally */
    text-align: left; /* Center text horizontally */    
    font-family: 'Roboto', sans-serif;
    font-weight: regular;
    padding: 1rem 1rem 3rem 1rem;
  
  }

  .hidden{
    /* hide but keep the space used */
    visibility: hidden;
  }

  .isNew h2, .isNew h3{
    color: var(--extreme-highlight);
  }


.deleteIcon{
  position: absolute;
  display:flex;
  justify-content: flex-start;
  top: 10.3rem;
  padding-left: .3rem;
  width: 100%;
  
}

.deleteIcon {
  position:absolute;
  left: 0rem;
  top: .5rem;

}

.deleteIcon span {
  
  display: inline-block;
  cursor: pointer;
  margin-bottom: 0rem;
  height: 1.7rem;
  width: 1.7rem;
  background-image: url('../../../public/images/delete.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  outline: none;
  background-color: transparent;
}

.deleteIcon span:hover {
  background-image: url('../../../public/images/deletePrimaryAlt.png');
}
