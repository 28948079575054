.loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    border: .2rem solid var(--primary-alternate-color);
    border-radius: .5rem;
    width: 300px;
    padding: 3rem;
    margin: 0 auto;
  }
  
  .loginForm {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .formGroup {
    margin-bottom: 1rem;
  }
  
  .formGroup label {
    margin-bottom: .3rem;
    font-weight: bold;
    color:var(--primary-color);
  }
  
  .formGroup input {
    padding: 8px;
    border: 2px solid var(--tertiary-color);
    border-radius: .3rem;
    width: 100%;
    background-color: var(--primary-alternate-color);
  }
  
  .submitButton {
    padding: .7rem;
    background-color: var(--secondary-color);
    color: var(--tertiary-color);
    border: none;
    border-radius: .3rem;
    cursor: pointer;
  }
  
  .submitButton:hover {
    background-color: #0056b3;
  }
  
  .googleButton {
    padding: .7rem;
    background-color: var(--tertiary-color);
    color: var(--primary-color);
    border: none;
    border-radius: .3rem;
    cursor: pointer;
    margin-top: .7rem;
  }
  
  .googleButton:hover {
    background-color: #c23321;
  }