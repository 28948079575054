body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-image: url('../../../public/images/backgrounds/5.jpg'); /* Add your background image path */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.container {
  position: relative; /* Make sure the container is above the overlay */
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  z-index: 2; /* Ensure content is above the overlay */
}

.overlay {
  position: fixed; /* Make the overlay cover the entire page */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  opacity: 0.9;
  z-index: -1; /* Sit behind the content */
}

.content{
  position: relative; /* Make sure the container is above the overlay */
  z-index: 2; /* Ensure content is above the overlay */
  width:Infinity;
  height:Infinity;
}




