

.main{
    --textSize: 4rem;
    color: var(--tertiary-color);
    font-family: 'Roboto', sans-serif;
    font-size: var(--textSize);
    font-weight: regular;
    
}
.main span {
    margin-right: .4rem;
}

.aceIt{
    font-style: italic;
}

.brain{
    position:relative;
    top: 2rem;
    left: 0rem;
}
