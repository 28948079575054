

  
  .featureCard {
    background-color: var(--primary-color);
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    transition: transform 0.3s ease, font-weight 0.3s ease, background-image 0.3s ease;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    font-family: Poppins, sans-serif;
    font-weight: bold;
    font-size: 1.1rem;
    display: flex; /* Use Flexbox */
    flex-direction: column; /* Arrange children in a column */
    justify-content: center; /* Center children vertically */
    align-items: center; /* Center children horizontally */
    text-align: center; /* Center text horizontally */
  }
  
  .featureCard::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 0;
  }
  
  .featureCard:hover {
    transform: translateY(-10px);
  }
  
  .featureCard:hover::before {
    opacity: 0.3;
  }
  
  .featureCard h2, .featureCard p {
    position: relative;
    z-index: 2;
    color: #333;
  }
  
  .featureCard::before {
    background-image: var(--featureCardUrl);
  }
  
  .content{
    font-weight: regular;
    font-family: Roboto, sans-serif;
    font-size: .8rem;
    display: flex; /* Use Flexbox */
    flex-direction: column; /* Arrange children in a column */
    justify-content: left; /* Center children vertically */
    align-items: left; /* Center children horizontally */
    text-align: left; /* Center text horizontally */    
  }
