@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


.icon{

    background-image: url('../../../public/images/brain.webp');
    background-size: cover;
    background-position: center;
    width: 5.3rem;
    height: 5rem;

    animation: spin 2s linear infinite;    
}

.text{
    color:white;
    font-size: 2.5rem;
    font-family: Poppins, sans-serif;
    font-weight: bold;
    position:relative;
    left: 1rem;
}


.innerDiv{ 
    position: absolute;
    top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%); 
    width: 100%;
    /*align the items in the content in the center but one on top of the other*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--primary-color);
}

.outerDiv{ 
    display: flex;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,var(--overlay-transparency));
    z-index: 1000;
}
