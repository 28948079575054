

.main {
    max-width: 100%;
    overflow: hidden;
    color: var(--primary-alternate-color);
    padding-right:10rem;
    padding: 0;


}

.main select {
    min-width: 12rem;
    max-height: 1.9rem;
    font-size: 1rem;
    font-family: Poppins, sans-serif;
    font-weight: bold;
    color: var(--background-color);
    background-color: var(--primary-color);
    border: 3px solid var(--secondary-color);
    border-radius: 0.25rem;
    transition: border-color 0.3s, box-shadow 0.3s;
    margin-left:1rem;
    
}

.main select:focus {
    border-color: var(--secondary-alternate-color);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    outline: none;
}

.main select:hover {
    border-color: var(--tertiary-color);
}