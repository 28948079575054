html {
    font-size: 16px; /* Default root font size */
  }
  
  @media (max-width: 1200px) {
    html {
      font-size: 15px; /* Adjust root font size for medium screens */
    }
  }
  
  @media (max-width: 992px) {
    html {
      font-size: 14px; /* Adjust root font size for smaller screens */
    }
  }
  
  @media (max-width: 768px) {
    html {
      font-size: 13px; /* Adjust root font size for tablets */
    }
  }
  
  @media (max-width: 576px) {
    html {
      font-size: 12px; /* Adjust root font size for mobile devices */
    }
  }

  @media (max-width: 488px) {
    html {
      font-size: 10px; /* Adjust root font size for mobile devices */
    }
  }

  @media (max-width: 400px) {
    html {
      font-size: 8px; /* Adjust root font size for mobile devices */
    }
  }

  @media (max-width: 300px) {
    html {
      font-size: 6px; /* Adjust root font size for mobile devices */
    }
  }

  @media (max-width: 200px) {
    html {
      font-size: 4px; /* Adjust root font size for mobile devices */
    }
  }